<!-- NavigationTop.vue -->
<template>
  <div class="row mx-3 mt-5 justify-content-center" style="gap: 0.5rem">
    <div v-for="(fase, index) of fases" :key="fase.id"
      class="col-12 col-md-2 bg-dark bg-dark-hover p-3 rounded d-flex justify-content-between" :style="{
        transition: 'box-shadow 0.3s ease',
        boxShadow: activeFase === index ? 'inset 0 0 0 2px #1A5CFF' : 'none',
        cursor: 'pointer',
      }" @click="navigateToSection(fase.id, index)">
      {{ fase.text }}
      <i class="fas fa-circle-check icon" :class="{ show: activeFase === index }"></i>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      fases: [
        { id: 1, text: "Resultados por evaluado" },
        { id: 2, text: "Cargos aceptados" },
        { id: 3, text: "Seguimiento Evaluacion" },
      ],
    };
  },
  computed: {
    ...mapState("reportesApex", {
      activeFase: (state) => state.activeFase,
    }),
  },
  methods: {
    ...mapActions("reportesApex", ["setActiveFase"]),
    navigateToSection(id, index) {
      this.setActiveFase(index);

      const sectionId = this.getSectionId(id);
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    getSectionId(id) {
      switch (id) {
        case 1:
          return "resultados-evaluado";
        case 2:
          return "cargos-aceptados";
        case 3:
          return "control-evaluaciones";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.icon {
  opacity: 0;
  transition: 0.3s;
}

.icon.show {
  opacity: 1;
  transform: scale(1.2);
}
</style>
